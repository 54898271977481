import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import ResultItem from "./ResultItem";
import { Divider, Typography, CircularProgress, Grid } from "@mui/material";
import FileOverlay from "../Modal/FileOverlay";
import SearchContext from "../../contexts/SearchContext";
import { StyledButton } from '../../styles/Buttons';

const ResultBoundry = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  width: '100%',
  padding: theme.spacing(4)
}));

export default function ResultsList(props) {
  const [dense, setDense] = React.useState(false);
  const [secondary, setSecondary] = React.useState(true);
  const [results, setResults] = React.useState([]);
  const [savedFile, setSavedFile] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [showButtons, setShowButtons] = React.useState(false);

  const search = React.useContext(SearchContext);


  React.useEffect(() => {
    setResults(props.results);
  }, [props.results])


  React.useEffect(() => {
    setShowButtons(!(search.results.length === 0 && search.pagination.offset === 0))
  }, [search.pagination, search.results])

  const openModal = (file) => {
    setSavedFile(file)
    setOpen(true)
  }

  const onClose = () => {
    setOpen(false)
  }

  const pagination = (dir) => {
    const limit = search.pagination.limit;
    const offset = search.pagination.offset;
    search.searchCallback(search.query, limit, dir === 1 ? offset + limit : offset - limit,)
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <FormGroup sx={{ padding: 4, display: 'none' }} row>
        <FormControlLabel
          control={
            <Checkbox
              checked={dense}
              onChange={(event) => setDense(event.target.checked)}
            />
          }
          label="Show dense"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={secondary}
              onChange={(event) => setSecondary(event.target.checked)}
            />
          }
          label="Show context"
        />
      </FormGroup>
      <Grid container spacing={2}>
        <ResultBoundry>
          <Divider />

          <Grid
            container
            justifyContent={'space-between'}
            alignContent={'flex-start'}
            sx={{ pt: 2, display: showButtons ? 'flex' : 'none' }}
          >
            <StyledButton disabled={search.pagination.offset === 0} onClick={() => { pagination(0) }} variant='outlined'>Prev</StyledButton>
            <StyledButton disabled={results.length < 10} onClick={() => { pagination(1) }} variant='outlined'>Next</StyledButton>
          </Grid>
          {search.searching ?
            <Grid
              container
              justifyContent={'center'}
              alignContent={'center'}
              sx={{ height: '10vh' }}
            >
              <CircularProgress />
            </Grid> :
            results.length > 0 ?
              <List dense={dense}>
                {results.map((result, index) => {
                  return (
                    <ResultItem key={index} result={result} secondary={secondary} query={props.query}
                      openModal={openModal} />
                  )
                })}
              </List>
              :
              <Typography variant={'h6'}>No results</Typography>

          }


        </ResultBoundry>
      </Grid>
      {savedFile ?
        <FileOverlay open={open} file={savedFile} onClose={onClose} />
        : ''
      }

    </Box>
  );
}
