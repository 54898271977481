import { SBImage, text2Image } from "../utils/ImageProcessor";
const SB = require("snackabra")
const pdfjsLib = require(`pdfjs-dist`)
pdfjsLib.GlobalWorkerOptions.workerSrc = process.env.PUBLIC_URL + '/static/media/pdf.worker.min.js'

class BamfFile {
    name
    type
    created
    text
    contents
    indexes
    sbImage
    SB
    ocrManager
    objectMetadata = {
        index: null,
        content: null
    }
    processingReady = new Promise((resolve) => {
        this.processingResolve = resolve;
    });
    indexesReady = new Promise((resolve) => {
        this.indexesResolve = resolve;
    });

    /**
     * 
     * @param {SBContext} SB 
     * @param {OcrWorkerManager} ocrManager 
     */
    constructor(SB, ocrManager) {
        if (!SB) {
            throw new Error("SB Connection not passed to BamfFile")
        }
        if (!ocrManager) {
            throw new Error("OCR Manager missing")
        }
        this.SB = SB
        this.ocrManager = ocrManager
    }

    /**
     * 
     * @param {File} file 
     * @param {*} options 
     * @returns 
     */
    import(file, options) {
        return new Promise((resolve, reject) => {

            try {
                this.parseBufferToText(file).then(async (text) => {
                    this.text = text;
                    this.name = file.name
                    this.type = file.type
                    let date = new Date();
                    if (!file.type.match(/^image/) && !file.type.match(/^application\/pdf/)) {
                        this.sbImage = await this.getThumbnailFromText(text)
                    }
                    if (file.type.match(/^image/)) {
                        this.sbImage = new SBImage(file, this.SB);
                    }

                    this.created = `${date.getFullYear()}-${date.getMonth()}-${date.getDay()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`
                    this.sbImage.img.then((i) => {
                        this.sbImage.url = i.src
                        queueMicrotask(() => {
                            const SBImageCanvas = document.createElement('canvas');
                            this.sbImage.loadToCanvas(SBImageCanvas).then((c) => {
                                console.log("BAMF File loaded to canvas")
                            });
                            this.sbImage.processThumbnail().then((c) => {
                                console.log("BAMF File thumbnail created")
                            });
                        });
                        resolve(this)
                    })
                })
            } catch (e) {
                reject(e.message)
            }
        })

    }

    /**
     * 
     * @param {Buffer} textBuffer 
     */
    getThumbnailFromText(textBuffer) {
        return new Promise(async (resolve) => {
            const dec = new TextDecoder()
            const thumbnail = await text2Image(dec.decode(textBuffer), this.name)
            resolve(new SBImage(thumbnail, this.SB))
        })

    }

    /**
     * 
     * @param {File} file 
     */
    parseBufferToText(file) {
        return new Promise((resolve, reject) => {
            let enc = new TextEncoder();
            const reader = new FileReader()

            reader.onabort = () => reject('file reading was aborted')
            reader.onerror = () => reject('file reading has failed')
            reader.onload = () => {
                this.contents = reader.result;
                if (file.type.match(/^image/)) {
                    console.log('[parseBufferToText()] Image file detected')
                    this.ocrManager.doOCR(reader.result).then((text) => {
                        resolve(enc.encode(text).buffer);
                    });
                } else if (file.type.match(/^text/)) {
                    console.log(`[parseBufferToText()] Text file detected`)
                    resolve(reader.result)
                } else if (file.type.match(/^application\/pdf/)) {
                    console.log(`[parseBufferToText()] PDF detected`)

                    this.pdf2Image(reader.result).then((text) => {
                        console.log(`[parseBufferToText()] PDF Rendered`)
                        resolve(enc.encode(text).buffer)
                    })
                } else {
                    reject('Unknown file type ' + file.type)
                }
            }
            reader.readAsArrayBuffer(file)
        })

    }
    /**
     * 
     * @param {Buffer} buffer 
     * @returns Promise<string>
     */
    pdf2Image(buffer) {
        return new Promise((res, rej) => {
            console.log(`[pdf2Image()] Loading pdf...`)

            let loadingTask = pdfjsLib.getDocument(buffer)
            loadingTask.promise.then((pdf) => {
                let rawText;
                let pagePromises = []
                let totalPages = pdf.numPages
                let currentPage = 1
                //console.log(pagePromises)
                // While we still have pages...
                while (currentPage <= totalPages) {
                    pagePromises.push(this.getPdfPage(currentPage, totalPages, pdf))
                    currentPage++
                }

                Promise.all(pagePromises).then((pages) => {
                    rawText = pages.join(' ')
                    //console.log(rawText)
                    res(rawText)
                })
                //loadingTask.promise.then
            })
            // return promise
        })
        // pdf2img
    }

    /**
     * 
     * @param {Buffer} currentPage 
     * @param {number} totalPages 
     * @param {Buffer} pdf 
     * @returns 
     */
    getPdfPage(currentPage, totalPages, pdf) {
        return new Promise((resolve, reject) => {
            console.log(`[pdf2Image()] Loading page ${currentPage}/${totalPages}...`)
            pdf.getPage(currentPage).then((page) => {
                // Render the page to a canvas and OCR the result

                // [TODO] More research on OffscreenCanvas -- could be beneficial
                //    let canvas = new OffscreenCanvas(width, height)
                let canvas = document.createElement("canvas");
                let context = canvas.getContext('2d')
                let viewport = page.getViewport({ scale: 1 })
                let outputScale = window.devicePixelRatio || 1

                canvas.width = Math.floor(viewport.width * outputScale)
                canvas.height = Math.floor(viewport.height * outputScale)
                canvas.style.width = Math.floor(viewport.width) + 'px'
                canvas.style.height = Math.floor(viewport.height) + 'px'

                let transform = outputScale !== 1 ? [outputScale, 0, 0, outputScale, 0, 0] : null
                let renderContext = {
                    canvasContext: context,
                    transform: transform,
                    viewport: viewport,
                }

                page.render(renderContext).promise.then(() => {
                    console.log(`[pdf2Image()]] Page ${currentPage} rendered.  Generating image...`)
                    if (currentPage === 1) {
                        canvas.toBlob((blob) => {
                            const file = new File([blob], this.name + '.png', {
                                type: blob.type,
                            });
                            this.sbImage = new SBImage(file, this.SB)
                            // Base64 encoded, convert to ArrayBuffer
                            let imgDataUrl = canvas.toDataURL()
                            let imgData = SB.base64ToArrayBuffer(imgDataUrl.split(',')[1])

                            console.dir(`[pdf2Image()] Performing OCR on pdf...`)
                            this.ocrManager.doOCR(imgData).then(
                                (text) => {
                                    canvas.remove();
                                    resolve(text)
                                },
                                (error) => {
                                    canvas.remove();
                                    console.error(error)
                                    reject(error)
                                }
                            )
                        })
                    } else {
                        // Base64 encoded, convert to ArrayBuffer
                        let imgDataUrl = canvas.toDataURL()
                        let imgData = SB.base64ToArrayBuffer(imgDataUrl.split(',')[1])

                        console.dir(`[pdf2Image()] Performing OCR on pdf...`)
                        this.ocrManager.doOCR(imgData).then(
                            (text) => {
                                canvas.remove();
                                resolve(text)
                            },
                            (error) => {
                                canvas.remove();
                                console.error(error)
                                reject(error)
                            }
                        )
                    }

                })
            })
        })
    }

    setIndexes(indexes) {
        return new Promise((resolve) => {
            this.indexes = indexes
            this.indexesResolve()
            resolve()
        })
    }

    processObject = () => {
        const t0 = new Date().getTime();
        return new Promise( (resolve, reject) => {
            try {
                this.indexesReady.then(async () => {
                    let enc = new TextEncoder();
                    const i = await this.SB.storage.getObjectMetadata(enc.encode(JSON.stringify(this.indexes)).buffer, 'p')
                    const c = await this.SB.storage.getObjectMetadata(this.contents, 'p')
                    const t1 = new Date().getTime();
                    console.warn(`#### image processing total ${t1 - t0} milliseconds (blocking)`);
                    this.objectMetadata = {
                        index: i,
                        content: c
                    }
                    this.processingResolve()
                    resolve(this)
                })
            } catch (e) {
                console.error(e)
                reject(e)
            }
        })
    }

    getStorePromises = (roomId) => {
        return new Promise(resolve => {
            this.processingReady.then(() => {
                const indexStorePromise = this.SB.storage.storeObject(this.objectMetadata.index.paddedBuffer, 'p', roomId, this.objectMetadata.index)
                const contentStorePromise = this.SB.storage.storeObject(this.objectMetadata.content.paddedBuffer, 'p', roomId, this.objectMetadata.content)
                resolve({
                    indexStorePromise: indexStorePromise,
                    contentStorePromise: contentStorePromise,
                });
            })
        })

    }
}

export default BamfFile