import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import DescriptionIcon from '@mui/icons-material/Description';
import { Hidden, ImageListItem, Toolbar } from "@mui/material";
import FileOverlay from "./Modal/FileOverlay";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from '@mui/icons-material/Delete';
import FormControlLabel from "@mui/material/FormControlLabel";

export default function FileGrid(props) {

  const [savedFile, setSavedFile] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [demensions, setDemensions] = React.useState({ col: 8, height: 192 });
  const [selected, setSelected] = React.useState([]);

  React.useEffect(() => {
    function handleResize() {
      // Using if statements for performance
      if (window.innerWidth <= 599) {
        setDemensions({ col: 2, height: 192 })
      }
      if (window.innerWidth >= 600 && window.innerWidth <= 899) {
        setDemensions({ col: 4, height: 192 })
      }
      if (window.innerWidth >= 900 && window.innerWidth <= 1199) {
        setDemensions({ col: 6, height: 192 })
      }
      if (window.innerWidth >= 1200 && window.innerWidth <= 1535) {
        setDemensions({ col: 8, height: 192 })
      }
      if (window.innerWidth >= 1536) {
        setDemensions({ col: 8, height: 192 })
      }

    }

    window.addEventListener('resize', handleResize)
    handleResize();
  }, [])

  const openModal = (file) => {
    setSavedFile(file)
    setOpen(true)
  }

  const onClose = () => {
    setOpen(false)
  }

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const handleClick = (event, file) => {
    const selectedIndex = selected.indexOf(file.id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, file.id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = props.files.map((n) => n.hash);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  return (
    <div style={{ flexGrow: 1 }}>
      <Toolbar sx={{ flexGrow: 1 }}>
        <IconButton size={'large'} disableRipple>
          {/* <FormControlLabel
            control={
              <Checkbox
                onClick={handleSelectAllClick}
                label={'Select All'}
              />
            }
            label="Select All"
          /> */}

        </IconButton>
        <Hidden xsUp={selected.length === 0}>
          <IconButton size={'large'} onClick={() => {
            props.onDelete(selected)
          }}>
            <DeleteIcon />
          </IconButton>
        </Hidden>
      </Toolbar>

      <ImageList sx={{ flexGrow: 1, width: '100%', overflow: 'hidden' }}
        cols={demensions.col} rowHeight={demensions.height} gap={2}>

        {props.files.map((file, index) => {
          return (<ImageListItem key={index} sx={{ width: '100%', cursor: 'pointer' }}>
            {/* <div style={{ position: "absolute", backgroundColor: 'rgba(55, 55, 55, 0.2)', borderRadius: '60px' }}>
              <Checkbox
                checked={selected.indexOf(file.id) !== -1}
                onClick={(e) => {
                  handleClick(e, { hash: file.id, name: file.name })
                }}
              />
            </div> */}
            <div onClick={() => {
              openModal(file)
            }}>
              <ParseImage image={file} />
              <ImageListItemBar
                title={file.name}
              />
            </div>
          </ImageListItem>)
        }
        )}
        {savedFile ?
          <FileOverlay open={open} file={savedFile} onClose={onClose} />
          : ''
        }
      </ImageList>
    </div>
  );
}

const ParseImage = (props) => {
  const { image } = props
  return (<img
    style={{ maxWidth: '100%', maxHeight: '100%' }}
    src={`${image.thumbnail}`}
    srcSet={`${image.thumbnail}`}
    alt={image.name}
    loading="lazy"
  />)
}
