import * as React from "react"
import ResponsiveDialog from "../ResponsiveDialog";
import { Grid } from "@mui/material";
import { StyledButton } from "../../styles/Buttons";
import { useState } from "react";
import Typography from "@mui/material/Typography";
import { SnackabraContext } from "mobx-snackabra-store";

export default function PurgeDialog(props) {
  const sbContext = React.useContext(SnackabraContext);
  const [open, setOpen] = useState(props.open);
  const [text, updateText] = useState('Purging your local cache will require the application to retrieve all assets, are you sure?')
  const [submitClick, setSubmitClick] = useState(false);

  React.useEffect(() => {
    setOpen(props.open)
  }, [props.open])


  const submit = async () => {
    document.cacheDb.openCursor(/.+/, (item, key) => {
      if (item instanceof Array) {
        for (let x in item) {
          if (item[x].key) {
            console.log('Purging ' + x)
            document.cacheDb.removeItem(item[x].key).then(() => {
              console.log('Delete ' + item[x].key + ' from cache')
            })
          }
        }
      }

    })
    sbContext.cleanCache().then(() => {
      setOpen(false)
      props.onClose();
    })
  }

  const cancel = async () => {
    setOpen(false)
    props.onClose();
  }

  const onClose = () => {
    setOpen(false)
    props.onClose();
  }

  return (
    <ResponsiveDialog
      title={'Purge Local Cache'}
      onClose={onClose}
      open={open}>
      <Grid style={{ width: 500 }}
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start">
        <Grid item xs={12} sx={{ pb: 2, pt: 2 }}>
          <Typography variant={'body1'}>{text}</Typography>
        </Grid>
        <Grid container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start">
          <StyledButton variant={'outlined'} onClick={cancel}>I'm scared, get me out</StyledButton>
          <StyledButton variant={'outlined-danger'} onClick={submit}>Nuke it</StyledButton>
        </Grid>
      </Grid>
    </ResponsiveDialog>
  )

}
