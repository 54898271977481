import * as React from "react";
import FileBrowserContext from "./FileBrowserContext";
// const { Index } = require("flexsearch");
const SearchContext = React.createContext(undefined);

export const SearchProvider = ({ children }) => {
  // Passed from the search bar
  const FileBrowser = React.useContext(FileBrowserContext);
  const [query, setQuery] = React.useState("");
  const [searching, setSearching] = React.useState(false);
  const [pagination, setPagination] = React.useState({ limit: 10, offset: 0 });
  // results should be an array of objects {name: string, contents: string, created: Date}
  const [results, setResults] = React.useState([]);
  // Call back to perform search on change in value from search bar
  const searchCallback = async (query, limit = 10, offset = 0) => {

    console.log(offset, limit)
    setPagination({ limit: limit, offset: offset })
    setResults([]);
    const startTime = performance.now();
    // If we are displaying the FileBrowser, we'll want to close it
    // upon performing a search
    if (FileBrowser.showDir) {
      FileBrowser.setShowDir(false);
    }

    const q_result = await window.dndx.search(
      query,
      {
        enrich: true,
        limit: limit,
        offset: offset
      });
    let promises = []
    if (q_result.length > 0 && query.length !== 0) {
      for (let i in q_result[0].result) {
        const element = q_result[0].result[i].id
        if (typeof element === 'string') {
          promises.push(getResult(i, q_result[0].result[i].doc));
        }
      }
      Promise.all(promises).then((res) => {
        const searchTime = performance.now();
        console.log('Query completed in ' + Math.round(searchTime - startTime) + 'ms');

        setResults(res);
        setSearching(false)
      })
    } else {
      setSearching(false)
    }
  };

  const getResult = (i, doc) => {
    return new Promise((res, rej) => {
      try {
        // mtg: hack to display context but way to slow
        const reg = new RegExp(`(.*${query}.*)`, "ig")
        let contexts = doc.content.match(reg);
        if(!contexts){
          contexts = doc.name.match(reg);
        }
        const minLength = Math.floor(contexts.reduce((a, b, i, arr) => a + b.length / arr.length, 0));
        const context = contexts.filter(row => row.length > minLength).splice(0, 4);
        const result = { id: pagination.offset + Number(i), filename: doc.name, context: context, hash: doc.id };
        res(result)
      } catch (e) {
        console.error(e)
        rej(e)
      }

    })
  }

  return (
    <SearchContext.Provider
      value={{
        query,
        pagination,
        setSearching,
        searching,
        setQuery,
        results,
        searchCallback,
      }}
    >
      {children}{" "}
    </SearchContext.Provider>
  );
};

export default SearchContext;
