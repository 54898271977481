const SB = require("snackabra")

export async function generateHash(data) {
  try {
    const digest = await crypto.subtle.digest('SHA-512', data);
    const _id = digest.slice(0, 32);
    const _key = digest.slice(32);
    return {
      id: encodeURIComponent(SB.arrayBufferToBase64(_id)),
      key: encodeURIComponent(SB.arrayBufferToBase64(_key))
    };
  } catch (e) {
    console.log(e);
    return {};
  }
}