import { SBImage, text2Image } from "../utils/ImageProcessor";
import { OCRClient } from 'tesseract-wasm'


class OcrWorkerManager {
    workers = []
    next_worker = 0
    max_workers = window.navigator.hardwareConcurrency

    constructor() {
        console.log(`setting up ${this.max_workers} OCR workers`)
        for (let i = 0; i < this.max_workers; i++) {
            let newWorker = {
                worker: new OCRClient(),
                i: i, // index/number of worker
                broken: false, // tracks if there's a problem
            }
            newWorker.worker.loadModel('eng.traineddata').then(() => {
                console.log(`OCR worker ${i} is ready`)
                this.workers.push(newWorker)
            })
        }
    }


    doOCR(contents) {
        console.log('--- doOCR() ---')
        console.dir(contents)

        var i = this.next_worker
        this.next_worker = (this.next_worker + 1) % this.max_workers
        var ocr = this.workers[i].worker
        return new Promise(
            (res, rej) => {
                createImageBitmap(new Blob([contents])).then((documentImage) => {
                    // const ocr = new OCRClient();
                    ocr.loadModel('eng.traineddata').then(() => {
                        const startTime = performance.now()
                        try {
                            console.log(`[${i}] Loading image`)
                            ocr.loadImage(documentImage).then(() => {
                                console.log('Recognizing text')
                                ocr.getText().then((text) => {
                                    // ocr.destroy();  // never do that
                                    const endTime = performance.now()
                                    console.log(
                                        `[${i}] ################ completed OCR in ${Math.round(endTime - startTime)} ms`
                                    )
                                    res(text)
                                })
                            })
                        } catch (err) {
                            console.log(`[${i}] OCR Failed`)
                            console.error(err)
                            rej('OCR failed')
                        }
                    })
                })
            },
            (error) => {
                console.log(error)
            }
        )
    }
}

export default OcrWorkerManager