import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import DownloadIcon from '@mui/icons-material/Download';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import DialogContent from "@mui/material/DialogContent";
import { Image } from 'mui-image'
import Grid from "@mui/material/Grid";
const SB = require("snackabra")

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FileOverlay(props) {
  const [open, setOpen] = React.useState(props.open);
  const [contents, setContent] = React.useState(null);
  const [isImage, setIsImage] = React.useState(false);
  const [isText, setIsText] = React.useState(false);
  const [file, setFile] = React.useState(null);

  React.useEffect(() => {
    setOpen(props.open)
  }, [props.open])

  React.useEffect(() => {
    console.log(props.file)
    setContent(props.file.url)
    if (props.file.type.match(/^image/)) {
      setIsImage(true)
    }else if(props.file.type.match(/^text/)){
      const buffer = SB.base64ToArrayBuffer(props.file.url.split('base64,')[1])
      let dec = new TextDecoder()
      setContent(dec.decode(buffer))
      setIsText(true)
    }else {
      setIsText(false)
      setIsImage(false)
    }

    setFile(props.file)
  }, [props.file])

  const createAndDownloadBlobFile = (file) => {
    const link = document.createElement('a');
    // Browsers that support HTML5 download attribute
    if (link.download !== undefined) {
      link.setAttribute('href', props.file.url ? props.file.url : file.contents);
      link.setAttribute('download', file.name);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  const downloadFile = async () => {
    createAndDownloadBlobFile(file);

  }

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={props.onClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative', backgroundColor: 'black', textTransform: 'none' }}>
          <Toolbar>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <IconButton
                edge="end"
                color="inherit"
                onClick={props.onClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <IconButton
                edge="end"
                color="inherit"
                onClick={downloadFile}
                aria-label="close"
              >
                <DownloadIcon />
              </IconButton>
            </Grid>
          </Toolbar>
        </AppBar>
        <DialogContent sx={{ p: 0 }}>
          {isImage ?
            <Image
              src={contents}
              height="100%"
              width="100%"
              fit="contain"
              duration={500}
              easing="cubic-bezier(0.7, 0, 0.6, 1)"
              showLoading={true}
              errorIcon={true}
              shift={null}
              distance="100px "
              shiftDuration={500}
              bgColor="inherit"
            />
            :
            isText ?
            <pre style={{ whiteSpace: 'break-spaces', padding: 32 }}>
              {contents}
            </pre>
            :<iframe src={`${contents}#view=fitH`} title={'pdf viewer'} height="100%" width="100%" />
            // <pre style={{ whiteSpace: 'break-spaces' }}>
            //   {contents}
            // </pre>
          }
        </DialogContent>
      </Dialog>
    </div>
  );
}
