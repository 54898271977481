import IndexDocumentWorker from './IndexDocumentWorker.js';
import { generateHash } from "../utils/misc";

let enc = new TextEncoder();
let dec = new TextDecoder();

// NOTE: flexsearch is supposed to have native worker support,
// but currently that doesn't work.  here we try our own.

// we need this so it can be packaged
export class BlobWorker extends Worker {
  constructor(worker, i) {
    const code = worker.toString();
    const blob = new Blob([`(${code})(${i})`]);
    return new Worker(URL.createObjectURL(blob));
  }
}

class FlexSearchIndexer {
  workers = []
  next_worker = 0
  active_workers = 0
  max_workers = window.navigator.hardwareConcurrency
  queue = []
  indexed = {}

  // constructor() {
  //   // console.log(`setting up ${this.max_workers} Index workers`)
  //   // for (let i = 0; i < this.max_workers; i++) {
  //   //   let newWorker = {
  //   //     worker: new BlobWorker(IndexDocumentWorker, i),
  //   //     i: i, // index/number of worker
  //   //     broken: false, // tracks if there's a problem
  //   //   }
  //   //   this.workers.push(newWorker)
  //   // }
  // }

  add(BamfFile) {
    this.queue.push(BamfFile)
  }
  /**
   * 
   */
  index(BamfFile) {
    return new Promise((resolve, reject) => {
      var i = this.next_worker++
      // this.next_worker = (this.next_worker + 1) % this.max_workers
      // this.active_workers++
      var instance = new BlobWorker(IndexDocumentWorker, i)
      generateHash(BamfFile.contents).then((hash) => {

        if (BamfFile.contents.byteLength > 0) {
          console.log(`got a blob of size ${BamfFile.text.byteLength} sending to worker ${hash.id}`);
          instance.onmessage = (m) => {
            console.log(`Index recevied from worker ${i}`)
            const index = JSON.parse(dec.decode(m.data));
            index.indexes.forEach((item) => {

              window.dndx.import(item[0], item[1])

            })
            BamfFile.setIndexes(index.indexes)
            instance.terminate()
            resolve(BamfFile)
          }
          try {
            const toWorker = {
              text: dec.decode(BamfFile.text),
              hash: hash.id,
              name: BamfFile.name
            }
            instance.postMessage(enc.encode(JSON.stringify(toWorker)).buffer);
          } catch (e) {
            console.log(e)
            console.error(`Failed to send task to worker ${hash.id}`);
            instance.terminate()
            reject("failed");
          }
        } else {
          instance.terminate()
          reject(`[${hash.id}] did not get anything to work with`);
        }

      })
    })
  }

  exec() {
    return new Promise((resolve, reject) => {
      let processed = 0;
      if (this.queue.length === 0) {
        reject("Queue is empty, did you forget to add to the queue first?")
      }
      this.indexed = {};

      this.queue.forEach((BamfFile) => {
        var i = this.next_worker
        this.next_worker = (this.next_worker + 1) % this.max_workers
        var instance = this.workers[i].worker
        generateHash(BamfFile.text).then((hash) => {

          if (BamfFile.text.byteLength > 0) {
            console.log(`got a blob of size ${BamfFile.text.byteLength} sending to worker ${hash.id}`);
            instance.onmessage = (m) => {
              console.log(`Index recevied from worker ${i}`)
              const index = JSON.parse(dec.decode(m.data));
              index.indexes.forEach((item) => {

                window.dndx.import(item[0], item[1])

              })
              BamfFile.setIndexes(index.indexes)
              this.indexed[hash.id] = {
                BamfFile: BamfFile
              }
              processed++
              if (processed === this.queue.length - 1 || this.queue.length === 1) {
                resolve(this.indexed)
              }
            }
            try {
              const toWorker = {
                text: dec.decode(BamfFile.text),
                hash: hash.id,
                name: BamfFile.name
              }
              instance.postMessage(enc.encode(JSON.stringify(toWorker)).buffer);
            } catch (e) {
              console.log(e)
              console.error(`Failed to send task to worker ${hash.id}`);
              reject("failed");
            }
          } else {
            reject(`[${hash.id}] did not get anything to work with`);
          }

        })
      })
    })
  }
}

export default FlexSearchIndexer