import * as React from "react"
import { SnackabraContext } from "mobx-snackabra-store";
import { observer } from "mobx-react"

const FileBrowserContext = React.createContext(undefined);

export const FileBrowserProvider = observer(({ children }) => {
  const sbContext = React.useContext(SnackabraContext);
  const [files, setFiles] = React.useState([]);
  const [folder, setFolder] = React.useState(sbContext.activeroom)
  const [showDir, setShowDir] = React.useState(false);

  React.useEffect(() => {
    setFolder(sbContext.activeroom)
  }, [sbContext.activeroom])

  const getFiles = () => {
    let fileArray = []
    const regEx = new RegExp(`^${sbContext.activeroom}_file.*`)
    document.cacheDb.openCursor(regEx, (r) => {
      if (r) {
        r.forEach((item) => {
          if (typeof item.value !== 'string' && item.value.hasOwnProperty('name')) {
            fileArray.push(item.value)
          }

        })
      }
    }).then(() => {
      setFiles(fileArray)
    })
  }

  const openFile = async (id) => {
    const file = await document.cacheDb.getItem(id)
    console.log(file)
    setFiles([...files, file])
  }

  return (<FileBrowserContext.Provider value={{
    files,
    folder,
    setFiles,
    showDir, setShowDir,
    getFiles,
    openFile
  }}>{children} </FileBrowserContext.Provider>)
});

export default FileBrowserContext;

