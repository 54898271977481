import * as React from 'react'
import ListItemText from '@mui/material/ListItemText'
import ListItem from '@mui/material/ListItem'
import { Divider } from '@mui/material'
import { SnackabraContext } from "mobx-snackabra-store";

function ResultContext(props) {
  const regEx = new RegExp(props.query, 'ig')

  const highlightText = (x) => {
    return `<span style="background-color: #FEF8B9"> ${x} </span>`
  }

  // Graceful error handling
  if (props.text) {
    return (<div>
      {props.text.map((text, i) => {
        return <div key={i} ><span dangerouslySetInnerHTML={{ __html: text.replace(regEx, highlightText) }} /><hr /></div>
      })

      }
    </div>)

  } else {
    return (
      <span>
        <p>-- ERROR: Content undefined --</p>
      </span>
    )
  }
}

export default function ResultItem(props) {
  const sbContext = React.useContext(SnackabraContext);
  return (
    <div>
      <ListItem>
        <ListItemText
          primary={
            <span
              style={{ cursor: 'pointer' }}
              onClick={async () => {
                const savedFile = await document.cacheDb.getItem(sbContext.activeroom + '_file_' +props.result.hash)
                props.openModal(savedFile)
              }}
            >
              {'File: ' + props.result.filename}
            </span>
          }
          secondaryTypographyProps={{
            component: 'div'
          }}
          secondary={
            props.secondary ? <ResultContext text={props.result.context} query={props.query} /> : ''
          }
        />
      </ListItem>
      <Divider />
    </div>
  )
}
