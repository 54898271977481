import * as React from 'react';
import { Grid, Switch, Toolbar } from "@mui/material";
import EnhancedTable from "./FilesList";
import FileBrowserContext from "../contexts/FileBrowserContext";
import ClearIcon from "@mui/icons-material/Clear";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FileGrid from "./FileGrid";
import { SnackabraContext } from "mobx-snackabra-store";

export default function FileExplorer() {
  const sbContext = React.useContext(SnackabraContext);
  const FileBrowser = React.useContext(FileBrowserContext)
  const [grid, setGrid] = React.useState(true);

  React.useEffect(()=>{
    FileBrowser.getFiles();
  }, [])

  const closeFileExplorer = () =>{
    FileBrowser.getFiles();
    FileBrowser.setShowDir(false);
  }

  const onDelete = (toDelete) => {
    toDelete.forEach(async (item) => {
      console.log("Deleteing ")
      await document.cacheDb.removeItem(sbContext.activeroom + item)
    })
    FileBrowser.getFiles();
  }

  const handleChange = (event) => {
    setGrid(event.target.checked);
  };
  return (
    <Grid container>
      <Toolbar sx={{ flexGrow: 1, width: '100%' }}>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          File Explorer
        </Typography>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={grid}
                onChange={handleChange}
                aria-label="login switch"
              />
            }
            label={'Show Grid'}
          />
        </FormGroup>
        <IconButton onClick={closeFileExplorer} component="label">
          <ClearIcon sx={{ color: 'gray' }} />
        </IconButton>
      </Toolbar>
      {grid
        ? <FileGrid files={FileBrowser.files} onDelete={onDelete} />
        : <EnhancedTable rows={FileBrowser.files} onDelete={onDelete} />
      }

    </Grid>
  );
}
