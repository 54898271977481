/* eslint-disable no-restricted-globals */

/*
  for interactive testing of libraries and such, try:
  script = document.createElement("script")
  script.type = "module"
  script.async = false
  script.src = "https://cdnjs.cloudflare.com/ajax/libs/lz-string/1.4.4/lz-string.js"
  document.head.appendChild(script)
*/

export default (i) => {  // eslint-disable-line
  console.log(`starting worker number ${i}`);

  function exportIndex(flexSearchIndex) {
    // https://github.com/nextapps-de/flexsearch/issues/299
    // https://github.com/nextapps-de/flexsearch/issues/274
    return new Promise((res, rej) => {
      let pkg = [];
      const expected = new Set([
        "reg",
        "content.cfg",
        "content.map",
        "content.ctx",
        "name.cfg",
        "name.map",
        "name.ctx",
        "name.tag",
        "name.store",

      ]);
      const received = new Set();

      const setsEq = (a, b) => {
        if (a.size !== b.size) {
          return false;
        }
        return Array.from(a).every(el => b.has(el));
      };

      flexSearchIndex.export((key, data) => {
        // https://github.com/nextapps-de/flexsearch/issues/290
        // https://github.com/nextapps-de/flexsearch/issues/273
        pkg.push([
          key,
          data
        ]);
        received.add(key);
        if (setsEq(expected, received)) {
          res(JSON.stringify(pkg));
        }
      });
    });
  }

  // from https://cdnjs.com/libraries/lz-string
  self.importScripts("https://cdnjs.cloudflare.com/ajax/libs/lz-string/1.4.4/lz-string.js");

  // self.importScripts("./lib/flexsearch.bundle.js");
  self.importScripts("https://bamf.pages.dev/flexsearch.bundle.js");

  // wasm-flate
  // self.importScripts("https://unpkg.com/wasm-flate@0.1.11-alpha/dist/bootstrap.js");
  // console.log("window.g_flate = flate");
  // console.log(flate); // eslint-disable-line
  // window.g_flate = flate; // eslint-disable-line

  self.onmessage = (ab) => {
    const dec = new TextDecoder();
    const p = JSON.parse(dec.decode(ab.data));
    // console.log(p.name)
    // console.log(`starting document index on worker ... `);
    const index = new FlexSearch.Document({ document: { id: "id", index: ["content", "name"], store: true } }); // eslint-disable-line
      index.add({ id: p.hash, content: p.text, name: p.name })
      exportIndex(index).then(function (x) {
        // console.log(`[${i}] new export says (size ${x.length})`);
        const j = JSON.parse(x);
        const result = { id: p.hash, indexes: j }
        const enc = new TextEncoder();
        const j_ab = enc.encode(JSON.stringify(result)).buffer;
        postMessage(j_ab, [j_ab]);
      }).catch(console.error)

  }
}
