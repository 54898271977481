import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';

function TransitionRight(props) {
    return <Slide {...props} direction="right" />;
}

export default function ProcessingPopper(props) {
    const [open, setOpen] = React.useState(props.open);
    const [message, setMessage] = React.useState(props.message);
    const [transition, setTransition] = React.useState(() => TransitionRight);

    React.useEffect(() => {
        if (props.message) {
            setMessage(props.message)
        }
    }, [props.message])

    React.useEffect(() => {
        setOpen(props.open)
    }, [props.open])

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Snackbar
                open={open}
                // anchorOrigin={'right'}
                onClose={handleClose}
                TransitionComponent={transition}
                message={<>
                    {message}
                </>}
                key={transition ? transition.name : ''}
            />
        </div>
    );
}