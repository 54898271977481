import * as React from 'react';
import { AppBar, Box, Grid, Toolbar, Typography } from "@mui/material";
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import SearchContext from "../contexts/SearchContext";
import IconButton from "@mui/material/IconButton";
import ClearIcon from '@mui/icons-material/Clear';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import FileBrowserContext from "../contexts/FileBrowserContext";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import FirstVisitDialog from "./Modal/FirstVisitDialog";
import PurgeDialog from "./Modal/PurgeDialog";

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

class NavAppBar extends React.Component {
  timeout;
  state = {
    open: false,
    openPurge: false
  }

  clearQuery = (search) => {
    search.setQuery('')
  }

  toggleDirectory = (FileBrowser) => {
    FileBrowser.setShowDir(!FileBrowser.showDir)
  }

  toggleDialog = () => {
    this.setState({
      open: !this.state.open
    })
  }

  togglePurgeDialog = () => {
    this.setState({
      openPurge: !this.state.openPurge
    })
  }

  performSearch = (search, e) => {
    search.setQuery(e.target.value)
    search.setSearching(true)
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
    this.timeout = setTimeout(() => {
      search.searchCallback(e.target.value)
    }, 300);


  }


  render() {

    return (
      <SearchContext.Consumer>
        {search => (
          <FileBrowserContext.Consumer>
            {FileBrowser => (
              <Box sx={{ flexGrow: 1 }}>
                <FirstVisitDialog open={this.state.open} onClose={() => {
                  this.toggleDialog()
                }} />
                <PurgeDialog open={this.state.openPurge} onClose={() => {
                  this.togglePurgeDialog()
                }} />
                <AppBar position="fixed" sx={{ backgroundColor: 'black', textTransform: 'none' }}>
                  <Toolbar>
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <div />
                      <Search>
                        <SearchIconWrapper>
                          <SearchIcon />
                        </SearchIconWrapper>
                        <StyledInputBase
                          onChange={(e) => this.performSearch(search, e)}
                          placeholder="Search…"
                          value={search.query}
                          inputProps={{ 'aria-label': 'search' }}
                        />
                        {search.query !== ''
                          ? <IconButton onClick={() => this.clearQuery(search)} component="label">
                            <ClearIcon sx={{ color: 'gray' }} />
                          </IconButton>
                          : ''
                        }

                      </Search>
                      {FileBrowser.folder !== undefined ?
                        <Grid item>
                          <Grid container
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center">
                            <Typography variant='body2' style={{color:"#aaa"}}>v{process.env.REACT_APP_CLIENT_VERSION}</Typography>
                            <IconButton onClick={this.togglePurgeDialog} component="label">
                              <DeleteForeverIcon sx={{ color: 'gray' }} />
                            </IconButton>
                            <IconButton onClick={() => this.toggleDirectory(FileBrowser)} component="label">
                              <FolderOutlinedIcon sx={{ color: 'gray' }} />
                            </IconButton>
                          </Grid>
                        </Grid> :
                        <Grid item>
                          <Typography variant='body2'>v{process.env.REACT_APP_CLIENT_VERSION}</Typography>
                        </Grid>
                      }

                    </Grid>
                  </Toolbar>
                </AppBar>
              </Box>
            )}
          </FileBrowserContext.Consumer>
        )}
      </SearchContext.Consumer>
    );
  }

}

export default NavAppBar
