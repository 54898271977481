import React, { useCallback, useMemo } from 'react'

import { useDropzone } from 'react-dropzone'
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import { observer } from "mobx-react"
import FileProcessorContext from "../contexts/FileProcessing/FileProcessorContext";
import Queue from "../utils/Queue";

// import { flate } from 'wasm-flate';
// import * as flate from 'wasm-flate';
// var flate = require('wasm-flate');

// this is actually from global index.html
// window.g_flate = flate; // eslint-disable-line

const baseStyle = {
  flex: 1,
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
}

const focusedStyle = {
  borderColor: '#2196f3',
}

const acceptStyle = {
  borderColor: '#00e676',
}

const rejectStyle = {
  borderColor: '#ff1744',
}

const q = new Queue()

const DropZone = observer((props) => {
  const FileProcessor = React.useContext(FileProcessorContext)
  const [success, setSuccess] = React.useState(false)

    React.useEffect(()=>{
    processQueue()
  }, [])

    const processQueue = (p_timeout) =>{
    if(p_timeout){
      clearTimeout(p_timeout)
    }
    while (!q.isEmpty && !q.isMaxed) {
      console.log("Max", q.isMaxed)
      q.processing++
      console.log("Processing", q.processing)
      const file = q.dequeue()
      FileProcessor.importFromFile(file).then(()=>{
        q.processing--
      })
    }
    let timeout = setTimeout(()=>{
      processQueue(timeout)
    }, 50)
  }

  React.useEffect(() => {
    if (success) {
      setTimeout(() => {
        setSuccess(false)
      }, 5000)
    }
  }, [success])


  const onDropCallback = (acceptedFiles) => {
    window.startTime = performance.now();
    processFiles(acceptedFiles)
  }

  const processFiles = useCallback((acceptedFiles) => {

    acceptedFiles.forEach((file) => {
      q.enqueue(file)
    })
  }, [])
  //This is where we would want to do something with the files when they are uploaded
  //https://mozilla.github.io/pdf.js/examples/
  const onDrop = useCallback(onDropCallback, [processFiles])
  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject
  } = useDropzone({ onDrop })

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  )

  return (
    <Grid {...getRootProps({ style })}
      container
      direction="row"
      justifyContent="center"
      alignItems="center">
      <input {...getInputProps()} />
      <Typography variant={'h4'} textAlign='center'>
        Drag 'n' drop some files here, or click to select files
      </Typography>
    </Grid>
  )

})

export default DropZone
