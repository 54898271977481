import * as React from "react"
import ResponsiveDialog from "../ResponsiveDialog";
import { Grid, TextField, Typography } from "@mui/material";
import { StyledButton } from "../../styles/Buttons";
import { useState } from "react";

const channel_id = 'k8VkoqI5s9swY90FyWkfSao0z0V8TdZseV2O9lCOBVK5zrv2BfAPKjETaeVa0v0A'

export default function FirstVisitDialog(props) {
  const [open, setOpen] = useState(props.open);
  const [text, setText] = useState(channel_id);
  const [submitClick, setSubmitClick] = useState(false);

  React.useEffect(() => {
    setOpen(props.open)
  }, [props.open])

  const updateText = (e) => {
    setText(e.target.value)
  }

  const submit = async () => {
    setSubmitClick(true)
    await document.Snackabra.connect(text)
    props.onClose();
  }

  const onClose = () => {
    if (!submitClick) {
      setOpen(true)
    } else {
      setOpen(false)
    }
  }

  return (
    <ResponsiveDialog
      title={'Change channel'}
      onClose={onClose}
      open={open}>
      <Grid style={{ width: 500 }}
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start">
        <Grid item xs={12} sx={{ pb: 2, pt: 2 }}>
          <TextField
            multiline
            id="channel-id"
            placeholder="Enter channel ID"
            value={text}
            fullWidth
            onChange={updateText}
          />
        </Grid>
        <StyledButton variant={'outlined'} onClick={submit}>Ok</StyledButton>
      </Grid>
    </ResponsiveDialog>
  )

}

/*
          <JwModal id='lastvisit-empty'>
            <br />
            <br />
            <input type="text" id='public-username-input' placeholder="Enter Username Here" onFocus={(event) => event.target.select()} autoFocus />
            <br />
            <button className='admin-button green-btn' id='acknowledge-localstorage-btn' onClick={(e) => {
              localStorage.setItem(this.roomId + '_username', document.getElementById('public-username-input') && document.getElementById('public-username-input').value)
              JwModal.close('lastvisit-empty')(e);
              this.selectRoom(this.roomId);
            }}><Trans id='ok button text'>Ok</Trans></button>
            <button className='admin-button green-btn' id='cancel-localstorage-btn' onClick={(e) => {
              window.location.href = '{ process.env.PUBLIC_URL }'
            }}><Trans id='cancel button text'>Cancel</Trans></button>
          </JwModal>

 */
