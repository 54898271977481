import * as React from "react"
import { SnackabraContext } from "mobx-snackabra-store";
import ProcessingPopper from "./ProcessingPopper";
import OcrWorkerManager from '../../utils/OcrWorkerManager';
import FlexSearchIndexer from '../../lib/FlexSearchIndexer';
import BamfFile from "../../utils/BamfFile";
const SB = require("snackabra")
const ocrManager = new OcrWorkerManager()
const indexer = new FlexSearchIndexer()

const FileProcessorContext = React.createContext(undefined);


export class FileProcessorProvider extends React.Component {
  sbContext
  messageQueue = 0
  fileQueue = 0
  state = {
    messageQueue: 0,
    fileQueue: 0
  }

  /**
   * 
   * @param {SBMessage} msg 
   */
  importFromMessage(msg) {
    this.messageQueue++
    this.setState({ messageQueue: this.messageQueue })
    return this.importFromMessagePromise(msg)
  }

  importFromMessagePromise(msg) {
    return new Promise((resolve, reject) => {
      try {
        const _id = msg.objectMetadata.indexId;
        const _i_control_msg = this.sbContext.messages.find((ctrl) => ctrl.id && ctrl.id === _id)
        if (_i_control_msg) {
          const SbObjHandle = {
            version: '1',
            type: 'p',
            id: _i_control_msg.id,
            key: msg.objectMetadata.indexKey,
            verification: _i_control_msg.verificationToken
          }
          this.sbContext.SB.storage.fetchData(SbObjHandle).then((data) => {
            let dec = new TextDecoder()
            const indexes = JSON.parse(dec.decode(data))
            const _id = msg.objectMetadata.contentId;
            const _i_control_msg = this.sbContext.messages.find((ctrl) => ctrl.id && ctrl.id === _id)
            if (_i_control_msg) {
              const SbObjHandle = {
                version: '1',
                type: 'p',
                id: _i_control_msg.id,
                key: msg.objectMetadata.contentKey,
                verification: _i_control_msg.verificationToken
              }
              this.sbContext.SB.storage.fetchData(SbObjHandle).then(async (data) => {
                indexes.forEach((item) => {
                  window.dndx.import(item[0], item[1])
                })
                const flexId = Object.keys(JSON.parse(indexes[0][1]))[0]
                document.cacheDb.setItem(this.sbContext.activeroom + '_indexes_' + flexId, JSON.stringify(indexes))
                const toSave = {
                  id: _id,
                  url: 'data:' + msg.type + ';base64,' + SB.arrayBufferToBase64(data, 'b64'),
                  type: msg.type,
                  created: msg.created,
                  name: msg.name
                }
                const buffer = SB.base64ToArrayBuffer(toSave.url.split('base64,')[1]);
                const blob = new Blob([buffer]);
                const file = new File([blob], toSave.name, {
                  type: toSave.type,
                });
                const BFile = new BamfFile(this.sbContext.SB, ocrManager)
                BFile.import(file).then(() => {
                  BFile.sbImage.thumbnailReady.then(() => {
                    toSave.thumbnail = BFile.sbImage.thumbnail;
                    document.cacheDb.setItem(this.sbContext.activeroom + '_file_' + flexId, toSave)
                    this.messageQueue--
                    this.setState({ messageQueue: this.messageQueue })
                    resolve()
                  })
                })

              })
            }
          })
        }
      } catch (e) {
        console.error(e)
        this.messageQueue--
        this.setState({ messageQueue: this.messageQueue })
        reject(e)
      }

    })
  }
  /**
   * 
   * @param {File} File 
   * @returns 
   */
  importFromFile(File) {
    this.fileQueue++
    this.setState({ fileQueue: this.fileQueue})
    return this.importFromFilePromise(File)
  }

  importFromFilePromise(File) {
    return new Promise((resolve, reject) => {
      try {
        const bmf = new BamfFile(this.sbContext.SB, ocrManager);
        bmf.import(File).then((BamfFile) => {
          BamfFile.processObject()
          indexer.index(BamfFile).then((indexed) => {
            const sbImage = indexed.sbImage;
            const BamfFile = indexed;
            sbImage.thumbnailReady.then(async () => {
              const storePromises = await BamfFile.getStorePromises(this.sbContext.activeroom)
              let contentMessage = new SB.SBMessage(this.sbContext.socket)
              contentMessage.contents.image = sbImage.thumbnail
              const objectMetadata = {
                indexId: BamfFile.objectMetadata.index.id,
                indexKey: BamfFile.objectMetadata.index.key,
                contentId: BamfFile.objectMetadata.content.id,
                contentKey: BamfFile.objectMetadata.content.key,
              }
              const toSave = {
                id: objectMetadata.contentId,
                url: 'data:' + BamfFile.type + ';base64,' + SB.arrayBufferToBase64(BamfFile.contents, 'b64'),
                type: BamfFile.type,
                created: BamfFile.created,
                name: BamfFile.name,
                thumbnail: BamfFile.sbImage.thumbnail
              }
              const flexId = Object.keys(JSON.parse(BamfFile.indexes[0][1]))[0]
              const saved = await document.cacheDb.getItem(this.sbContext.activeroom + '_indexes_' + flexId)
              if (saved) {
                resolve()
              }
              document.cacheDb.setItem(this.sbContext.activeroom + '_indexes_' + flexId, JSON.stringify(BamfFile.indexes))
              let dec = new TextDecoder()
              contentMessage.contents.text = dec.decode(BamfFile.text).slice(0, 64) + '...';
              contentMessage.contents.objectMetadata = objectMetadata;
              contentMessage.contents.name = BamfFile.name
              contentMessage.contents.type = BamfFile.type
              contentMessage.contents.created = BamfFile.created
              contentMessage.contents.app = "bamf"
              contentMessage.contents.flexId = flexId
              storePromises.indexStorePromise.then((indexVerification) => {
                indexVerification.verification.then((verification) => {
                  let controlMessage = new SB.SBMessage(this.sbContext.socket);
                  controlMessage.contents.control = true;
                  controlMessage.contents.verificationToken = verification;
                  controlMessage.contents.id = objectMetadata.indexId;
                  controlMessage.contents.app = "bamf"
                  controlMessage.send().then(() => {
                    console.log('Control message for index data sent!')
                  })
                })
                queueMicrotask(() => {
                  storePromises.contentStorePromise.then((verificationPromise) => {
                    verificationPromise.verification.then((verification) => {
                      console.info('Full file uploaded')
                      let controlMessage = new SB.SBMessage(this.sbContext.socket);
                      controlMessage.contents.control = true;
                      controlMessage.contents.verificationToken = verification;
                      controlMessage.contents.id = objectMetadata.contentId;
                      controlMessage.send().then(() => {
                        console.log('Control message for full file sent!')

                        document.cacheDb.setItem(this.sbContext.activeroom + '_file_' + flexId, toSave)
                        contentMessage.send();
                        this.fileQueue--
                        this.setState({ fileQueue: this.fileQueue})
                        resolve()
                      })
                    });
                  });
                })
              })
            })
          })
        })
      } catch (e) {
        console.error(e)
        this.fileQueue--
        this.setState({ fileQueue: this.fileQueue})
        reject(e)
      }
    })
  }


  performImport(msg) {

  }

  render() {
    return (
      <SnackabraContext.Consumer>
        {(sbContext) => {
          this.sbContext = sbContext;
          return (
            <FileProcessorContext.Provider value={this}>
              <ProcessingPopper open={this.state.fileQueue >= 1} message={`Processing ${this.state.fileQueue} files`} />
              <ProcessingPopper open={this.state.messageQueue >= 1} message={`Processing ${this.state.messageQueue} messages`} />
              {this.props.children}
            </FileProcessorContext.Provider>
          )
        }}
      </SnackabraContext.Consumer>
    )

  }

};

export default FileProcessorContext;